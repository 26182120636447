<template>
  <span></span>
</template>

<script>
export default {
  name: 'pdf-viewer',
  props: {
    link: {
      type: String,
      default: ''
    }
  },
  mounted () {
    const param = '?v=' + Math.round(Math.random() * 10000)
    if (navigator.userAgent.includes('Android')) {
      window.open(this.link + param, '_blank');
      setTimeout(() => {
        window.close()
      }, 1000);
    } else {
      window.location.href = this.link + param
    }
  }
}
</script>